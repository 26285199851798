import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import UserModel        from '@/api/models/user/UserModel'
import AuthRestApi      from '@/mixins/auth/authRestApi'
import PermissionEnum   from '@/api/enums/PermissionEnum'
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'

export default {
  name: 'Auth',
  data () {
    return {
      authError        : false,
      isAuthLoading    : false,
      isMaintenanceMode: false
    }
  },
  mixins: [AuthRestApi],
  enums : {
    PermissionEnum,
    ApiResponseEnum
  },
  methods: {
    userHasRoutePermission (to) {
      let hasPermission = true
      const route = to ? this.$router.resolve(to).route : this.$router.currentRoute
      if (route.meta.requiresAuth) hasPermission = this.userHasComponentPermission(route.name.toUpperCase(), PermissionEnum.ACCESS)
      return hasPermission
    },

    userHasComponentPermission (component, permission) {
      let hasPermission = false
      if (!component || !permission) return false
      if (this.userIsSuperAdmin || !this.$router.currentRoute.meta.requiresAuth) return true

      if (!this.user) {
        if (this.$router.currentRoute.name !== 'Login') {
          this.$router.replace({
            name : 'Login',
            query: {}
          })
        }
      } else {
        const permissionsArr = this?.user?.Permissions[component.toUpperCase()] || []
        if (permissionsArr && permissionsArr.some(p => p.toUpperCase() === permission.toUpperCase())) hasPermission = true
      }
      return hasPermission
    },

    userCan (component, permission) {
      return this.userHasComponentPermission(component, permission)
    }
  },
  computed: {
    authErrorMessage () {
      return this.$t('Users.Login.Error.Auth')
    },

    userKey () {
      return HmacMD5.hash('user')
    },

    authorizationTokenKey () {
      return HmacMD5.hash('authorizationToken')
    },

    authorizationToken: {
      get () {
        return AES.decrypt(this.$sessionStorage.get(this.authorizationTokenKey))
      },
      set (val) {
        this.$sessionStorage.set(this.authorizationTokenKey, AES.encrypt(val))
      }
    },

    user: {
      get () {
        const sessionUser = this.$sessionStorage.get(this.userKey)
        if (this.$dataStore.User) {
          return new UserModel(AES.decrypt(this.$dataStore.User))
        } else if (sessionUser) {
          this.$dataStore.User = sessionUser
          return new UserModel(AES.decrypt(sessionUser))
        }
        return null
      },
      set (val) {
        this.$dataStore.User = AES.encrypt(val)
        this.$sessionStorage.set(this.userKey, this.$dataStore.User)
        if (val === null) {
          this.$dataStore.User = null
          this.$sessionStorage.remove(this.userKey)
          this.$sessionStorage.remove(this.authorizationTokenKey)
        }
      }
    },
    userCanAccess () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, PermissionEnum.ACCESS)
    },
    userCanAdd () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, PermissionEnum.ADD)
    },
    userCanDelete () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, PermissionEnum.DELETE)
    },
    userCanManage () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, PermissionEnum.MANAGE)
    },
    userIsAuthenticated () {
      return !!this.user
    },
    userIsSuperAdmin () {
      return this.user && this.user.CompanyId === 1 && this.user.Admin
    },
    userIsAdmin () {
      return this.user && this.user.RoleId === 11
    }
  }

}
