export default {

  Title: 'Ενώσεις',

  Table: {
    Button: {
      New: 'Νέα Ένωση'
    },

    Headers: {
      Id           : '#',
      CompanyName  : 'ΓΟΝΙΚΗ ΕΝΩΣΗ',
      Name         : 'ΟΝΟΜΑ',
      Description  : 'ΠΕΡΙΓΡΑΦΗ',
      EmailIdentity: 'ΑΠΟΣΤΟΛΕΑΣ EMAIL',
      SmsIdentity  : 'ΑΠΟΣΤΟΛΕΑΣ SMS',
      CreatedAt    : 'ΕΓΓΡΑΦΗ',
      Active       : 'ΕΝΕΡΓΗ',
      Actions      : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Ενώσεις',
        PlaceHolder: 'Όλες οι Ενώσεις',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ενεργές'
          },
          {
            Value: 0,
            Name : 'Ανενεργές'
          }
        ]
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Ένωσης {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Ένωσης',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή της Ένωσης {Id};'
    }
  },

  Form: {
    Title : 'Ένωσης',
    Fields: {
      CompanyId: {
        Label: 'Γονική Ένωση',
        Hint : ''
      },
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      EmailIdentity: {
        Label: 'Διεύθυνση αποστολέα Email',
        Hint : ''
      },
      SmsIdentity: {
        Label: 'Όνομα αποστολέα SMS',
        Hint : ''
      },
      Active: {
        Label: 'Ενεργή',
        Hint : ''
      }
    }
  }

}
