export default {
  Title: 'Δικαιώματα Χρηστών',

  Table: {
    Button: {
      New: 'Νέο Δικαίωμα Χρηστών'
    },

    Headers: {
      Id         : '#',
      Name       : 'ΟΝΟΜΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      Status     : 'ΕΝΕΡΓΟ',
      Action     : 'ΠΡΟΣΒΑΣΗ',
      Requires   : 'ΑΠΑΙΤΗΣΕΙΣ',
      Actions    : ''
    },

    Filter: {
      Status: {
        Type       : 'v-btn-toggle',
        Label      : 'Δικαιώματα Χρηστών',
        PlaceHolder: 'Όλα τα Δικαιώματα Χρηστών',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλα'
          },
          {
            Value: 1,
            Name : 'Ενεργά'
          },
          {
            Value: 0,
            Name : 'Ανενεργά'
          }
        ]
      },

      CompanyId: {
        Type       : 'v-select',
        Label      : 'Ενώσεις',
        PlaceHolder: 'Όλες οι Ενώσεις',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Δικαιώματος Χρηστών {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Δικαιώματος Χρηστών',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή του Δικαιώματος Χρηστών {Id};'
    }
  },

  Form: {
    Title : 'Δικαιώματος Χρηστών',
    Fields: {
      Id: {
        Label: 'Id',
        Hint : ''
      },
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      Admin: {
        Label: 'Μόνο για διαχειριστές',
        Hint : ''
      },
      Status: {
        Label: 'Δικαίωμα',
        Hint : ''
      },
      Parent: {
        Label: 'Γονικό Δικαίωμα',
        Hint : ''
      },
      Action: {
        Label: 'Τύπος Πρόσβασης',
        Hint : ''
      },
      Requires: {
        Label: 'Απαιτούμενα Δικαιώματα',
        Hint : ''
      }
    }
  }
}
