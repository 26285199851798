<template>
  <v-app>
    <vue-snotify />

    <app-navigation-drawer
      :items="navigationDrawerItemsAuth"
      :logo-visible="false"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <app-toolbar
      :items="toolbarItemsAuth"
      :logo-visible="true"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <v-main>
      <v-container
        fluid
        ma-0
        pa-0
      >
        <v-row>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <app-footer />

    <confirm-dialog ref="confirm" />
  </v-app>
</template>

<script>
import AppToolbar          from '@/theme/default/components/app/AppToolbar'
import AppNavigationDrawer from '@/theme/default/components/app/AppNavigationDrawer'
import AppFooter           from '@/theme/default/components/app/AppFooter'
import ConfirmDialog       from '@/theme/default/components/common/ConfirmDialog'
import PermissionEnum      from '@/api/enums/PermissionEnum'

export default {
  name      : 'DefaultLayout',
  components: {
    ConfirmDialog,
    AppNavigationDrawer,
    AppToolbar,
    AppFooter
  },
  mixins   : [],
  enums    : { PermissionEnum },
  dataStore: { Positions: 'Positions' },
  data     : function () {
    return {
      navigationDrawerVisible: false,
      toolbarItems           : [
        { divider: true },
        [
          {
            icon : this.$t('Route.Dashboard.Icon'),
            text : this.$t('Route.Dashboard.Title'),
            route: {
              name : 'Dashboard',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Elections.Icon'),
            text : this.$t('Route.Elections.Title'),
            route: {
              name : 'Elections',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Members.Icon'),
            text : this.$t('Route.Members.Title'),
            route: {
              name : 'Members',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Companies.Icon'),
            text : this.$t('Route.Companies.Title'),
            route: {
              name : 'Companies',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Users.Icon'),
            text : this.$t('Route.Users.Title'),
            route: {
              name : 'Users',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Settings.Icon'),
            text : this.$t('Route.Settings.Title'),
            route: {
              name : 'Settings',
              exact: true
            }
          },
          { divider: true }
        ]
      ],

      navigationDrawerItems: [
        {
          icon : this.$t('Route.Dashboard.Icon'),
          text : this.$t('Route.Dashboard.Title'),
          route: {
            name : 'Dashboard',
            exact: true
          }
        },
        {
          icon : this.$t('Route.Elections.Icon'),
          text : this.$t('Route.Elections.Title'),
          route: {
            name : 'Elections',
            exact: true
          }
        },
        {
          icon : this.$t('Route.ElectionCategories.Icon'),
          text : this.$t('Route.ElectionCategories.Title'),
          route: {
            name : 'ElectionCategories',
            exact: true
          }
        },
        {
          icon : this.$t('Route.Members.Icon'),
          text : this.$t('Route.Members.Title'),
          route: {
            name : 'Members',
            exact: true
          }
        },

        [
          { divider: true },
          {
            icon    : this.$t('Route.System.Icon'),
            text    : this.$t('Route.System.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Settings.Icon'),
                text : this.$t('Route.Settings.Title'),
                route: {
                  name : 'Settings',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Companies.Icon'),
                text : this.$t('Route.Companies.Title'),
                route: {
                  name : 'Companies',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Users.Icon'),
                text : this.$t('Route.Users.Title'),
                route: {
                  name : 'Users',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Roles.Icon'),
                text : this.$t('Route.Roles.Title'),
                route: {
                  name : 'Roles',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Permissions.Icon'),
                text : this.$t('Route.Permissions.Title'),
                route: {
                  name : 'Permissions',
                  exact: true
                }
              }
            ]
          }
        ]
      ]
    }
  },
  computed: {
    toolbarItemsAuth () {
      const retVal = []
      this.toolbarItems.forEach(item => {
        if (Array.isArray(item)) {
          let tmp = []
          item.forEach(child => {
            if (child.hasOwnProperty('divider') ||
              (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
              (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, PermissionEnum.ACCESS) && (this.userCan(child.route.name, PermissionEnum.MENU) || this.userCan(child.route.name, PermissionEnum.TOOLBAR)))
            ) {
              tmp.push(child)
            }
          })
          if (tmp.length === 1 && tmp[0].hasOwnProperty('divider')) tmp = []
          tmp.forEach(child => retVal.push(child))
        } else {
          if (item.hasOwnProperty('divider') || (item.hasOwnProperty('text') && !item.hasOwnProperty('route')) || (item.hasOwnProperty('text') && item.hasOwnProperty('route') && this.userCan(item.route.name, PermissionEnum.ACCESS) && (this.userCan(item.route.name, PermissionEnum.MENU) || this.userCan(item.route.name, PermissionEnum.TOOLBAR)))) {
            retVal.push(item)
          }
        }
      })

      return retVal
    },

    navigationDrawerItemsAuth () {
      const retVal = []

      this.navigationDrawerItems.forEach(item => {
        if (Array.isArray(item)) {
          let tmpc = []
          item.forEach(c => {
            if (c.hasOwnProperty('divider') ||
              (c.hasOwnProperty('text') && !c.hasOwnProperty('route')) ||
              (c.hasOwnProperty('text') && c.hasOwnProperty('route') && this.userCan(c.route.name, PermissionEnum.ACCESS) && this.userCan(c.route.name, PermissionEnum.MENU) && this.userCan(c.route.name, PermissionEnum.TOOLBAR))
            ) {
              if (c.hasOwnProperty('children')) {
                const tmp = []
                c.children.forEach(child => {
                  if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                    (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                    (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, PermissionEnum.ACCESS) && this.userCan(child.route.name, PermissionEnum.MENU) && this.userCan(child.route.name, PermissionEnum.TOOLBAR))
                  ) {
                    tmp.push(child)
                  }
                })
                if (tmp.length > 0) {
                  c.children = tmp
                  tmpc.push(c)
                }
              } else {
                tmpc.push(c)
              }
            }
          })
          if (tmpc.length === 1 && tmpc[0].hasOwnProperty('divider')) tmpc = []
          tmpc.forEach(child => retVal.push(child))
        } else {
          if (item.hasOwnProperty('divider') || item.hasOwnProperty('heading') ||
            (item.hasOwnProperty('text') && !item.hasOwnProperty('route')) ||
            (item.hasOwnProperty('text') && item.hasOwnProperty('route') && this.userCan(item.route.name, PermissionEnum.ACCESS) && this.userCan(item.route.name, PermissionEnum.MENU) && this.userCan(item.route.name, PermissionEnum.TOOLBAR))
          ) {
            if (item.hasOwnProperty('children')) {
              const tmp = []
              item.children.forEach(child => {
                if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                  (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                  (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, PermissionEnum.ACCESS) && this.userCan(child.route.name, PermissionEnum.MENU) && this.userCan(child.route.name, PermissionEnum.TOOLBAR))
                ) {
                  tmp.push(child)
                }
              })
              if (tmp.length > 0) {
                item.children = tmp
                retVal.push(item)
              }
            } else {
              retVal.push(item)
            }
          }
        }
      })

      return retVal
    }
  },
  watch: {},
  created () {},
  mounted () {
    this.$root.$confirm = this.$refs.confirm.open
  },
  updated () {},
  beforeDestroy () {},
  methods: {}
}

</script>

<style>

</style>
