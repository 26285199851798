/* IMPORTS START */
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'
import Http             from '@/lib/data/api/Http'
import GQL              from '@/lib/data/api/GQL'
import Setting          from '@/api/endpoint/Setting'
import Company          from '@/api/endpoint/Company'
import User             from '@/api/endpoint/User'
import Role             from '@/api/endpoint/Role'
import Permission       from '@/api/endpoint/Permission'
import Dashboard        from '@/api/endpoint/Dashboard'
import Config           from '@/api/endpoint/Config'
import Member           from '@/api/endpoint/Member'
import Election         from '@/api/endpoint/Election'
import ElectionCategory from '@/api/endpoint/ElectionCategory'
import ElectionMember   from '@/api/endpoint/ElectionMember'
import ElectionSetting  from '@/api/endpoint/ElectionSetting'
import ElectionResults  from '@/api/endpoint/ElectionResults'

/* IMPORTS END */

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },

  get GQLRequest () {
    return GQL.Request
  },

  get Resource () {
    return {
      /* RESOURCES */
      ElectionResults : ElectionResults,
      ElectionSetting : ElectionSetting,
      ElectionMember  : ElectionMember,
      ElectionCategory: ElectionCategory,
      Election        : Election,
      Member          : Member,
      Dashboard       : Dashboard,
      Company         : Company,
      User            : User,
      Role            : Role,
      Permission      : Permission,
      Setting         : Setting,
      Config          : Config
    }
  },

  get Endpoint () {
    return {
      /* ENDPOINTS */
      ElectionSetting: {
        Get: {
          Path  : 'election/change/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'election/change',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'election/change/{id}',
          Method: Http.Method.Patch
        }
      },

      ElectionResults: {
        Get: {
          Path  : 'election/votes/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'election/votes',
          Method: Http.Method.Post
        }
      },

      ElectionCategory: {
        List: {
          Path  : 'election-categories',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'election-category/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'election-category',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'election-category/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'election-category/{id}',
          Method: Http.Method.Delete
        }
      },

      Election: {
        List: {
          Path  : 'elections',
          Method: Http.Method.Post
        },
        Filters: {
          Path  : 'elections/filters',
          Method: Http.Method.Get
        },
        Get: {
          Path  : 'election/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'election',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'election/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'election/{id}',
          Method: Http.Method.Delete
        },
        Action: {
          Path  : 'election/action',
          Method: Http.Method.Post
        },
        Notification: {
          Path  : 'election/notification',
          Method: Http.Method.Post
        },
        SearchVoter: {
          Path  : 'election/voter',
          Method: Http.Method.Post
        },
        GeneratePdf: {
          Path  : 'election/pdf',
          Method: Http.Method.Post
        },
        ClearCache: {
          Path  : 'election/cc',
          Method: Http.Method.Post
        },
        SendVotersPdf: {
          Path  : 'election/notifyCommitteeVoters',
          Method: Http.Method.Post
        },
        SendResults: {
          Path  : 'election/notifyCommittee',
          Method: Http.Method.Post
        },
        CandidatesImport: {
          Path  : 'election/import',
          Method: Http.Method.Post
        },
        CandidatesSettingsImport: {
          Path  : 'election/candidates/import',
          Method: Http.Method.Post
        }
      },

      ElectionMember: {
        List: {
          Path  : 'election/members',
          Method: Http.Method.Post
        }
      },

      Member: {
        List: {
          Path  : 'members',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'member/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'member',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'member/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'member/{id}',
          Method: Http.Method.Delete
        },
        Upload: {
          Path  : 'members/import',
          Method: Http.Method.Post
        }
      },

      Config: {
        Get: {
          Path  : 'config',
          Method: Http.Method.Post
        }
      },

      Setting: {
        List: {
          Path  : 'settings',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'setting/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'setting',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'setting/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'setting/{id}',
          Method: Http.Method.Delete
        },
        Copy: {
          Path  : 'settings/copy',
          Method: Http.Method.Post
        }
      },

      Dashboard: {
        Get: {
          Path  : 'dashboard',
          Method: Http.Method.Post
        },
        GetAdmin: {
          Path  : 'dashboard-admin',
          Method: Http.Method.Post
        }
      },

      Company: {
        List: {
          Path  : 'companies',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'company/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'company',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'company/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'company/{id}',
          Method: Http.Method.Delete
        }
      },

      User: {
        List: {
          Path  : 'users',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'user/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'user',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'user/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'user/{id}',
          Method: Http.Method.Delete
        },
        Login: {
          Path  : 'login',
          Method: Http.Method.Post
        },
        Logout: {
          Path  : 'logout',
          Method: Http.Method.Post
        },
        ForgotPassword: {
          Path  : 'forgot-password',
          Method: Http.Method.Post
        },
        ResetPassword: {
          Path  : 'reset-password',
          Method: Http.Method.Post
        },
        ChangePassword: {
          Path  : 'change-password',
          Method: Http.Method.Post
        },
        TwoFALogin: {
          Path  : '2fa',
          Method: Http.Method.Post
        }
      },

      Role: {
        List: {
          Path  : 'roles',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'role/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'role',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'role/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'role/{id}',
          Method: Http.Method.Delete
        }
      },

      Permission: {
        List: {
          Path  : 'permissions',
          Method: Http.Method.Post
        },
        Get: {
          Path  : 'permission/{id}',
          Method: Http.Method.Get
        },
        Create: {
          Path  : 'permission',
          Method: Http.Method.Post
        },
        Update: {
          Path  : 'permission/{id}',
          Method: Http.Method.Patch
        },
        Delete: {
          Path  : 'permission/{id}',
          Method: Http.Method.Delete
        }
      },

      Settings: {
        Get: {
          Path  : 'config',
          Method: Http.Method.Get
        }
      }
    }
  },

  responseData     : (response) => response?.data?.data || null,
  responseErrors   : (response) => response?.data?.errors || null,
  isResponseSuccess: (response) => response?.status === ApiResponseEnum.SUCCESS && response.data?.success && API.responseData(response) && !API.responseErrors(response)
  // isResponseSuccess: (response) => response.status === 200 && response.data?.code === 200 && response.data?.success && response.data?.data
}

export { default as Http } from '@/lib/data/api/Http'
export default API
