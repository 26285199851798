<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    app
    temporary
    width="350"
  >
    <template #prepend>
      <template v-if="logoVisible">
        <v-row
          class="grey darken-4"
          dense
        >
          <v-col cols="12">
            <v-img
              class="ma-2"
              contain
              height="48"
              src="/img/logo/logo-light.png"
            />
          </v-col>
        </v-row>

        <v-divider />
      </template>

      <v-list
        color="grey darken-3"
        dark
        dense
        style="border-radius:0"
        tile
      >
        <v-list-item>
          <v-list-item-avatar color="brand-primary">
            <v-icon dark>
              mdi-account-circle
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="user">
            <v-list-item-title>{{ user.FullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.CompanyName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
    </template>

    <v-list
      dense
      nav
    >
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading"
          :key="`heading-${i}`"
          align="center"
        >
          <v-col
            class="text-left py-0 my-0"
            cols="6"
          >
            <v-subheader>
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
            class="text-right py-0 my-0"
            cols="6"
          >
            <v-btn
              v-if="item.text && item.route"
              :exact="item.route.exact"
              :to="item.route"
              small
              text
            >
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider
          v-else-if="item.divider"
          :key="`divider-${i}`"
          class="my-2"
        />

        <v-list-group
          v-else-if="item.children"
          :key="i"
          v-model="item.model"
          no-action
        >
          <template #activator>
            <v-list-item
              class="pl-0"
              style="height: 40px;"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  <v-subheader class="pl-0">
                    {{ item.text }}
                  </v-subheader>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(child, c) in item.children"
            :key="`child-${i}-${c}`"
            :exact="child.route.exact"
            :to="child.route"
            class="pl-2"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="`item-${i}`"
          :exact="item.route.exact"
          :to="item.route"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template #append>
      <v-divider />
      <v-btn
        block
        color="red accent-4"
        dark
        large
        tile
        @click="logout"
      >
        <v-icon left>
          mdi-logout
        </v-icon>
        {{ $t('Common.Button.Logout') }}
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>

export default {
  name      : 'AppNavigationDrawer',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    logoVisible: {
      type   : Boolean,
      default: true
    },
    navigationDrawer: {
      type    : Boolean,
      required: true
    },
    items: {
      type    : Array,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
