import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class UserValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    CompanyId: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    FirstName: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    LastName: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    Username: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3,
      maxLen  : 40
    },
    Password: {
      requiredIf: ['Id', null],
      type      : BaseValidator.Type.String,
      minLen    : 6,
      maxLen    : 16
    },
    Phone: {
      required  : false,
      type      : BaseValidator.Type.String,
      startsWith: '69',
      length    : 10
    },
    Email: {
      required: true,
      type    : BaseValidator.Type.Email,
      maxLen  : 96
    },
    RoleId: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    Locale: {
      required: true,
      type    : BaseValidator.Type.String
    },
    SingleLogin: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Admin: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    TwoFA: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    ForceChangePassword: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Notes: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 1024
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
