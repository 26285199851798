<template>
  <v-container
    v-if="election"
    v-resize="onResize"
    class="fill-height"
    fluid
  >
    <v-row
      class="elementHeight"
      dense
    >
      <v-col cols="12">
        <data-card
          :color="$t(`${parentComponentName}.Election.Color`)"
          :colored-border="true"
          :icon="$t(`${parentComponentName}.Election.Icon`)"
          :sub-title="election.Description"
          :title="election.Name"
          toolbar-height="46"
        >
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <mini-stats-icon-card
                    :sub-title="election.StartedAtFmt"
                    :title="election.ActionStartedAtFmt ? election.ActionStartedAtFmt : 'ΔΕΝ ΕΧΕΙ ΞΕΚΙΝΗΣΕΙ'"
                    color="primary"
                    color-class="primary"
                    flat
                    icon="mdi-update"
                    icon-background-color="white"
                    icon-title="ΕΝΑΡΞΗ"
                  />
                </div>

                <v-btn
                  v-if="!electionHasResults"
                  :disabled="!startElectionButtonEnabled"
                  block
                  class="mt-1 white--text"
                  color="primary"
                  large
                  @click="doAction({Action: 'start', Title: 'ΕΝΑΡΞΗ ΨΗΦΟΦΟΡΙΑΣ', Color: 'primary'})"
                >
                  ΕΝΑΡΞΗ ΨΗΦΟΦΟΡΙΑΣ
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <mini-stats-icon-card
                    :sub-title="election.EndedAtFmt"
                    :title="election.ActionEndedAtFmt ? election.ActionEndedAtFmt : 'ΔΕΝ ΕΧΕΙ ΛΗΞΕΙ'"
                    color-class="red"
                    flat
                    icon="mdi-update"
                    icon-background-color="white"
                    icon-title="ΛΗΞΗ"
                  />
                </div>

                <v-btn
                  v-if="!electionHasResults"
                  :disabled="!endElectionButtonEnabled"
                  block
                  class="mt-1 white--text"
                  color="red"
                  large
                  @click="doAction({Action: 'end', Title: 'ΛΗΞΗ ΨΗΦΟΦΟΡΙΑΣ', Color: 'red'})"
                >
                  ΛΗΞΗ ΨΗΦΟΦΟΡΙΑΣ
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <mini-stats-icon-card
                    :sub-title="election.ActionVotesAtFmt"
                    :title="election.ActionVotesAtFmt ? 'ΑΠΟΤΕΛΕΣΜΑΤΑ' : 'ΧΩΡΙΣ ΑΠΟΤΕΛΕΣΜΑΤΑ'"
                    color-class="light-green"
                    flat
                    icon="mdi-update"
                    icon-background-color="white"
                    icon-title="ΑΠΟΤΕΛΕΣΜΑΤΑ"
                  />
                </div>

                <v-btn
                  v-if="!electionHasResults"
                  :disabled="!votesElectionButtonEnabled"
                  block
                  class="mt-1 white--text"
                  color="light-green"
                  large
                  @click="doAction({Action: 'votes', Title: 'ΕΝΑΡΞΗ ΚΑΤΑΜΕΤΡΗΣΗΣ ΨΗΦΩΝ', Color: 'light-green'})"
                >
                  ΕΝΑΡΞΗ ΚΑΤΑΜΕΤΡΗΣΗΣ ΨΗΦΩΝ
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col
                cols="12"
                sm="4"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΨΗΦΟΦΟΡΟΙ'"
                  :sub-title="'ΣΥΝΟΛΟ'"
                  :title="String(election.MembersCount || 0)"
                  color-class="green"
                  flat
                  icon-background-color="white"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΣΥΜΜΕΤΟΧΗ'"
                  :sub-title="'ΣΥΝΟΛΟ'"
                  :title="String(election.ParticipationsCount || 0)"
                  color-class="brown darken-1"
                  flat
                  icon-background-color="white"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <mini-stats-icon-card
                  :icon="'mdi-ballot-recount'"
                  :icon-title="'ΨΗΦΟΙ'"
                  :sub-title="'ΣΥΝΟΛΟ'"
                  :title="String(election.VotesCount || 0)"
                  color-class="orange darken-2"
                  flat
                  icon-background-color="white"
                />
              </v-col>
            </v-row>
          </v-container>
        </data-card>
      </v-col>

      <v-col
        v-if="adminStats && (election.DashboardStats || userIsSuperAdmin || userIsAdmin)"
        cols="12"
      >
        <data-card
          :colored-border="true"
          :icon="$t(`${parentComponentName}.Election.Icon`)"
          :sub-title="election.Description"
          color="primary"
          title="ΣΤΑΤΙΣΤΙΚΑ"
          toolbar-height="46"
        >
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="12"
                sm="3"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΨΗΦΟΦΟΡΟΙ'"
                  :sub-title="'ΣΥΝΟΛΟ'"
                  :title="String(adminStats.TotalMembers || 0)"
                  color-class="primary darken-1"
                  flat
                  icon-background-color="white"
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΣΥΜΜΕΤΟΧΗ'"
                  :sub-title="'ONLINE'"
                  :title="String(adminStats.OnlineCount || 0)"
                  color-class="primary darken-1"
                  flat
                  icon-background-color="white"
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΣΥΜΜΕΤΟΧΗ'"
                  :sub-title="'OFFLINE'"
                  :title="String(adminStats.OfflineCount || 0)"
                  color-class="primary darken-1"
                  flat
                  icon-background-color="white"
                />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <mini-stats-icon-card
                  :icon="$t(`${parentComponentName}.Members.Icon`)"
                  :icon-title="'ΣΥΜΜΕΤΟΧΗ'"
                  :sub-title="'ΣΥΝΟΛΟ'"
                  :title="String(adminStats.VotersCount || 0)"
                  color-class="primary darken-1"
                  flat
                  icon-background-color="white"
                />
              </v-col>
            </v-row>
          </v-container>
        </data-card>
      </v-col>
    </v-row>

    <v-row
      v-if="(election.OfflineVoter || userIsSuperAdmin || userIsAdmin) && (!election.ActionEndedAtFmt && !election.ActionVotesAtFmt && !Array.isArray(election))"
      class="elementHeight"
      dense
    >
      <v-col cols="12">
        <data-card
          :color="$t(`${parentComponentName}.Search.Color`)"
          :colored-border="true"
          :icon="$t(`${parentComponentName}.Search.Icon`)"
          :title="$t(`${parentComponentName}.Search.Title`)"
          toolbar-height="46"
        >
          <v-container fluid>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model.trim="searchVoterModel"
                  :color="$t(`${parentComponentName}.Search.Color`)"
                  :disabled="searchLoading"
                  :error-messages="searchVoterMessages"
                  :loading="searchLoading"
                  :placeholder="$t(`${parentComponentName}.Search.Form.Field.Label`)"
                  :prepend-inner-icon="$t(`${parentComponentName}.Search.Form.Field.Icon`)"
                  clearable
                  filled
                  hide-details="auto"
                />
              </v-col>

              <v-col class="shrink">
                <v-btn
                  :class="[$t(`${parentComponentName}.Search.Color`), 'grey--text text--darken-3']"
                  :color="$t(`${parentComponentName}.Search.Color`)"
                  :disabled="searchLoading || !searchVoterModel || (searchVoterModel && searchVoterModel.length < 3)"
                  :loading="searchLoading"
                  depressed
                  height="57"
                  @click="searchVoter"
                >
                  <v-icon left>
                    {{ $t(`${ parentComponentName }.Search.Form.Button.Icon`) }}
                  </v-icon>
                  {{ $t(`${ parentComponentName }.Search.Form.Button.Label`) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </data-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <data-card
          :color="$t(`${parentComponentName}.Committee.Color`)"
          :colored-border="true"
          :icon="$t(`${parentComponentName}.Committee.Icon`)"
          :title="$t(`${parentComponentName}.Committee.Title`)"
          toolbar-class="elementHeight"
        >
          <v-card-text
            :style="$vuetify.breakpoint.smAndDown ? 'height: auto;' : `height: calc(100vh - ${electionStatsHeight}px)`"
            class="overflow-auto"
          >
            <v-list dense>
              <template v-for="(member, index) in electionCommittee">
                <v-list-item :key="`committee-member-${index}`">
                  <v-list-item-content>
                    <v-list-item-title :class="`${$t(`${parentComponentName}.Committee.Color`)}--text`">
                      {{ member.Title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ member.Name }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="caption font-weight-regular">
                      <span v-if="member.Email">{{ member.Email }}</span>&nbsp;<span
                        v-if="member.Phone"
                      >/ {{ member.Phone }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </data-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <data-card
          :color="$t(`${parentComponentName}.Candidates.Color`)"
          :colored-border="true"
          :icon="$t(`${parentComponentName}.Candidates.Icon`)"
          :title="$t(`${parentComponentName}.Candidates.Title`)"
        >
          <template
            v-if="electionHasResults"
            #toolbar-append
          >
            <v-btn
              :href="election.Pdf"
              color="primary"
              depressed
              target="_blank"
            >
              <v-icon left>
                mdi-download-box
              </v-icon>
              PDF
            </v-btn>
          </template>

          <v-card-text
            :style="$vuetify.breakpoint.smAndDown ? 'height: auto;' : `height: calc(100vh - ${electionStatsHeight}px)`"
            class="overflow-auto"
          >
            <v-list
              dense
              tile
            >
              <template v-for="(category, index) in electionCategories">
                <v-subheader
                  :key="`category-${index}`"
                  class="subtitle-2 font-weight-bold primary blue--text text--lighten-5 rounded pr-1"
                >
                  {{ category.Name }}

                  <v-spacer />

                  <v-chip
                    v-if="electionHasResults"
                    label
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      mdi-plus
                    </v-icon>
                    {{ category.Votes }}
                  </v-chip>
                </v-subheader>
                <v-divider :key="`category-divider-${index}`" />

                <template v-for="(candidate, idx) in category.Candidates">
                  <v-list-item
                    :key="`category-${index}-candidate-${idx}`"
                    class="px-1"
                    @click="() => {}"
                  >
                    <v-list-item-avatar
                      class="mx-1 caption grey--text"
                      size="30"
                    >
                      {{ (idx + 1) }}.
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ candidate.LastName }} {{ candidate.FirstName }}
                        {{ candidate.FathersName ? `του ${ candidate.FathersName }` : '' }} <span
                          v-if="candidate.Corps"
                          class="grey--text caption"
                        >({{ candidate.Corps }})</span>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="electionHasResults">
                      <v-chip
                        label
                        small
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-plus
                        </v-icon>
                        {{ candidate.Votes }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </template>
            </v-list>
          </v-card-text>
        </data-card>
      </v-col>
    </v-row>

    <voter-view-dialog
      :item="selectedVoter"
      :visible.sync="voterViewDialogVisible"
      @form:save:success="onVoterSaveSuccess"
    />

    <committee-action-dialog
      v-if="electionCommitteeHasMembers"
      :action="committeeAction"
      :election="election"
      :visible.sync="committeeActionDialogVisible"
      @form:save:success="onDoActionSuccess"
    />

    <v-overlay
      v-if="waitingResults && !userIsSuperAdmin"
      :opacity="0.75"
      color="black"
      fixed
      style="backdrop-filter: blur(8px)"
      z-index="3"
    >
      <div class="text-center">
        <v-progress-circular
          color="light-green"
          indeterminate
          size="64"
        />

        <h2 class="mt-8">
          ΑΝΑΜΟΝΗ ΑΠΟΤΕΛΕΣΜΑΤΩΝ
        </h2>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>

import i18nRouteMeta         from '@/mixins/i18n/i18nRouteMeta'
import DashboardModel        from '@/api/models/dashboard/DashboardModel'
import PermissionEnum        from '@/api/enums/PermissionEnum'
import API                   from '@/api/Api'
import DataCard              from '@/theme/default/components/common/DataCard'
import MiniStatsIconCard     from '@/theme/default/components/widgets/MiniStatsIconCard'
import CommitteeActionDialog from '@/theme/default/views/election/CommitteeActionDialog'
import VoterViewDialog       from '@/theme/default/views/member/VoterViewDialog'
import AutoUpdateApiData     from '@/mixins/api/autoUpdateApiData'

export default {
  name      : 'Dashboard',
  components: {
    VoterViewDialog,
    CommitteeActionDialog,
    MiniStatsIconCard,
    DataCard
  },
  directives: {},
  mixins    : [i18nRouteMeta, AutoUpdateApiData],
  props     : {},
  enums     : {},
  dataModel : DashboardModel,
  data () {
    return {
      electionStatsHeight         : 0,
      committeeAction             : null,
      committeeActionDialogVisible: false,
      searchLoading               : false,
      searchVoterModel            : '',
      searchVoterMessages         : '',
      parentComponentName         : this.$options.name,
      selectedVoter               : null,
      voterViewDialogVisible      : false,
      waitingResults              : false,
      model                       : null,
      stats                       : null,
      autoUpdateApiDataOptions    : {
        timeout  : 15000,
        autoStart: true,
        callback : 'getData'
      }
    }
  },
  computed: {
    election () {
      return this.model?.Election ?? {}
    },
    adminStats () {
      return this.stats?.Stats
    },
    electionCategories () {
      return this.election?.Categories
    },
    electionCommittee () {
      return this.election?.Committee || []
    },
    electionCommitteeHasMembers () {
      return !!this.electionCommittee.length
    },
    electionHasResults () {
      return !!this.election.Pdf
    },

    startElectionButtonEnabled () {
      return !this.election.ActionStartedAtFmt && this.electionCommitteeHasMembers
    },
    endElectionButtonEnabled () {
      return !this.election.ActionEndedAtFmt && !this.startElectionButtonEnabled && this.electionCommitteeHasMembers
    },
    votesElectionButtonEnabled () {
      return !this.election.ActionVotesAtFmt && !this.startElectionButtonEnabled && !this.endElectionButtonEnabled && this.electionCommitteeHasMembers
    }

  },
  watch: {
    searchVoterModel (newVal, oldVal) {
      if (newVal !== oldVal && this.searchVoterMessages) this.searchVoterMessages = ''
    }
  },
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    searchVoter () {
      if (!this.searchVoterModel || !this.election?.Id) return
      this.searchLoading = true

      API.Resource.Election.SearchVoter(this.searchVoterModel, this.election.Id)
        .then(response => {
          this.handleSearchVoterResponse(response)
        })
        .catch(e => {
          this.handleSearchVoterResponse(e.response)
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    handleSearchVoterResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.onSearchVoterSuccess(response, data)
      } else {
        this.onSearchVoterError(response, errors)
      }
    },
    onSearchVoterSuccess (response, data) {
      this.searchVoterModel = ''
      this.searchVoterMessages = ''

      this.selectedVoter = data
      this.voterViewDialogVisible = true
    },
    onSearchVoterError (response, errors) {
      this.searchVoterMessages = this.$t(`${ this.parentComponentName }.Search.Form.Error.NotFound`)
    },

    onVoterSaveSuccess (data) {
      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Success.Generic'),
        type   : 'success',
        icon   : 'mdi-check-circle',
        timeout: 3000
      })
    },

    doAction (action) {
      this.stopAutoUpdateApiData()
      this.committeeAction = action
      this.committeeActionDialogVisible = true
    },
    onDoActionSuccess (data) {
      if (!data) return

      this.election.ActionStartedAtFmt = data.ActionStartedAtFmt
      this.election.ActionEndedAtFmt = data.ActionEndedAtFmt
      this.election.ActionVotesAtFmt = data.ActionVotesAtFmt

      this.getData()
      this.startAutoUpdateApiData(this.getData)
    },

    getData () {
      if (!this.userCan('Dashboard', PermissionEnum.API)) return

      API.Resource.Dashboard.Get()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.model = API.responseData(response)

            if (this.election.ActionVotesAtFmt && this.election.ActionEndedAtFmt) {
              this.waitingResults = false
            } else {
              if (Array.isArray(this.election)) {
                this.waitingResults = true
              } else {
                this.waitingResults = false
              }
            }
          }
        })
        .catch(e => {
        })
        .finally(() => {
          this.onResize()
        })

      this.getAdminData()
    },

    getAdminData () {
      if (!this.userCan('Dashboard', PermissionEnum.API) || !(!this.election?.DashboardStats || this.userIsSuperAdmin || this.userIsAdmin)) return

      API.Resource.Dashboard.GetAdmin()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.stats = API.responseData(response)
          }
        })
        .catch(e => {
        })
        .finally(() => {
        })
    },

    setElementsSize () {
      const appToolbarHeight = 48
      const appFooterHeight = 56
      const offset = 1
      const elements = [...document.getElementsByClassName('elementHeight')]
      const totalHeight = elements?.reduce((a, b) => a + (b?.clientHeight || 0), 0) || 0
      this.electionStatsHeight = totalHeight + appToolbarHeight + appFooterHeight + offset
    },

    onResize () {
      setTimeout(() => {
        this.setElementsSize()
      }, 0)
    }
  }
}
</script>

<style scoped>

</style>
