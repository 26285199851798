<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-btn
                  v-if="userIsSuperAdmin && userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="primary"
                  @click="createItem()"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-plus
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`${ $options.name }.Table.Button.New`).toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <v-btn
                  v-if="userIsSuperAdmin && userCanManage"
                  :disabled="!dataTable.total || !selectedSettings.length"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1 white--text"
                  color="teal"
                  @click="openCopySettingsDialog"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-text-box
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t('Common.Button.Copy') }} <span v-if="selectedSettings.length">({{ selectedSettings.length }})</span>
                  </template>
                </v-btn>

                <div class="flex-grow-1" />

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            v-model="selectedSettings"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTableHeaders"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            :show-select="userIsSuperAdmin"
            fixed-header
            item-key="Id"
            selectable-key="isSelectable"
          >
            <template #[`item.data-table-select`]="{isSelected, select, item}">
              <v-checkbox
                v-if="!item.Global"
                :input-value="isSelected"
                @change="select"
              />
            </template>

            <template #[`item.CompanyId`]="{item}">
              {{ item.CompanyName }}
            </template>

            <template #[`item.Group`]="{item}">
              <v-chip
                class="d-inline-block text-center"
                label
                small
                style="min-width: 150px"
              >
                {{ item.Group.toLocaleUpperCase($i18n.locale) }}
              </v-chip>
            </template>

            <template #[`item.Value`]="{item}">
              <template v-if="Array.isArray(item.Value)">
                <template v-for="(val, key) in item.Value">
                  <div
                    v-if="val.Visible || userIsSuperAdmin"
                    :key="`item-arr-${key}`"
                    class="my-2"
                  >
                    <code v-if="item.Value.length > 0">{{ toTitleCase(val.Key) }}:</code>
                    <pre class="d-inline-block ml-1">{{ val.Cast === CastValueEnum.BOOL.Value ? val.Value ? $t('Common.Button.Yes') : $t('Common.Button.No') : val.Value }}</pre>
                  </div>
                </template>
              </template>

              <template v-else-if="typeof item.Value === 'object' && typeof item.Value !== 'string'">
                <template v-for="(val, key) in item.Value">
                  <div :key="`item-obj-${key}`">
                    {{ toTitleCase(key) }}: <span class="grey--text">{{ val }}</span>
                  </div>
                </template>
              </template>

              <template v-else>
                {{ item.Value }}
              </template>
            </template>

            <template #[`item.Active`]="{item}">
              <v-icon
                v-if="item.Active"
                color="green"
                v-text="`mdi-check-circle`"
              />
            </template>

            <template #[`item.Global`]="{item}">
              <v-icon
                v-if="item.Global"
                color="blue"
                v-text="`mdi-check-circle`"
              />
            </template>

            <template #[`item.Admin`]="{item}">
              <v-icon
                v-if="item.Admin"
                color="orange"
                v-text="`mdi-check-circle`"
              />
            </template>

            <template #[`item.ApiGroup`]="{item}">
              <template v-for="(obj, index) in item.ApiGroup">
                <div :key="`api-group-item-${index}`">
                  <v-chip
                    :color="getApiGroupColor(obj)"
                    class="mr-1 mb-1 justify-center"
                    dark
                    label
                    style="min-width: 60px"
                    x-small
                  >
                    {{ obj.toUpperCase() }}
                  </v-chip>
                </div>
              </template>
            </template>

            <template
              v-if="userCanDelete || userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <v-speed-dial
                v-model="item.fab"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  v-if="userIsSuperAdmin && userCanDelete"
                  :open-delay="650"
                  bottom
                  color="red"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userIsSuperAdmin && userCanDelete"
                      :disabled="item.loadingDelete"
                      :loading="item.loadingDelete"
                      class="ml-1 white--text"
                      color="red"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="deleteItem(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Delete')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanAccess"
                  :open-delay="650"
                  bottom
                  color="info"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanAccess"
                      :disabled="item.loadingView"
                      :loading="item.loadingView"
                      class="ml-1 white--text"
                      color="info"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="viewItem(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.View')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanManage"
                  :open-delay="650"
                  bottom
                  color="grey"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanManage"
                      :disabled="item.loadingEdit"
                      :loading="item.loadingEdit"
                      class="ml-1 white--text"
                      color="grey"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Edit')" />
                </v-tooltip>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <setting-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :companies="filterData.CompanyId.Items"
      :item="selectedItem"
      :item-view-mode="itemViewMode"
      :visible.sync="editDialogVisible"
      @form:save:success="getData"
    />

    <settings-copy-dialog
      v-if="userIsSuperAdmin && userCanManage"
      :companies="filterData.CompanyId.Items"
      :item="{SettingIds: selectedSettings || [], CompanyIds: []}"
      :visible.sync="copySettingsDialogVisible"
      @form:save:success="onCopySuccess"
    />
  </v-container>
</template>

<script>
import DataTable        from '@/mixins/table/dataTable'
import DataTableActions from '@/mixins/table/dataTableActions'
import i18nRouteMeta    from '@/mixins/i18n/i18nRouteMeta'
import SettingModel     from '@/api/models/setting/SettingModel'
import { themeUtils }   from '@/lib/utils'
import { toTitleCase }  from '@/lib/utils/string'
import PermissionEnum   from '@/api/enums/PermissionEnum'
import API              from '@/api/Api'
import { collect }      from '@/lib/utils/array'
import CompanyModel     from '@/api/models/company/CompanyModel'
import CastValueEnum    from '@/api/enums/CastValueEnum'
import settingColors    from '@/theme/default/views/system/setting/mixins/settingColors'

const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')
const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const SettingEditDialog = () => themeUtils.importThemeComponent('views/system/setting/SettingEditDialog')
const SettingsCopyDialog = () => themeUtils.importThemeComponent('views/system/setting/SettingsCopyDialog')

export default {
  name      : 'Settings',
  components: {
    DataTableSearch,
    DataTableFilter,
    SettingEditDialog,
    SettingsCopyDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, DataTable, DataTableActions, settingColors],
  props     : {},
  dataStore : {},
  enums     : { CastValueEnum },
  dataModel : SettingModel,
  data () {
    return {
      copySettingsDialogVisible: false,
      selectedSettings         : [],
      dataTable                : {
        customFilter: {
          ApiGroup: []
        },
        filter: {
          CompanyId: null,
          Active   : null,
          Global   : null,
          Admin    : null
        },
        options: {
          sortBy  : [],
          sortDesc: []
        }
      }
    }
  },
  computed: {
    dataTableHeaders () {
      if (this.userIsSuperAdmin) {
        return [
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Id`) ? this.$t(`${ this.$options.name }.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.CompanyName`) ? this.$t(`${ this.$options.name }.Table.Headers.CompanyName`) : 'CompanyName',
            value   : 'CompanyId',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Group`) ? this.$t(`${ this.$options.name }.Table.Headers.Group`) : 'Group',
            value   : 'Group',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Description`) ? this.$t(`${ this.$options.name }.Table.Headers.Description`) : 'Description',
            value   : 'Description',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Value`) ? this.$t(`${ this.$options.name }.Table.Headers.Value`) : 'Value',
            value   : 'Value',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Active`) ? this.$t(`${ this.$options.name }.Table.Headers.Active`) : 'Active',
            value   : 'Active',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Global`) ? this.$t(`${ this.$options.name }.Table.Headers.Global`) : 'Global',
            value   : 'Global',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Admin`) ? this.$t(`${ this.$options.name }.Table.Headers.Admin`) : 'Admin',
            value   : 'Admin',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.ApiGroup`) ? this.$t(`${ this.$options.name }.Table.Headers.ApiGroup`) : 'ApiGroup',
            value   : 'ApiGroup',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Actions`) ? this.$t(`${ this.$options.name }.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 40
          }
        ]
      } else {
        return [
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Id`) ? this.$t(`${ this.$options.name }.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Group`) ? this.$t(`${ this.$options.name }.Table.Headers.Group`) : 'Group',
            value   : 'Group',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Description`) ? this.$t(`${ this.$options.name }.Table.Headers.Description`) : 'Description',
            value   : 'Description',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Value`) ? this.$t(`${ this.$options.name }.Table.Headers.Value`) : 'Value',
            value   : 'Value',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Active`) ? this.$t(`${ this.$options.name }.Table.Headers.Active`) : 'Active',
            value   : 'Active',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Actions`) ? this.$t(`${ this.$options.name }.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 40
          }
        ]
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.dataTable.options.sortBy = this.userIsSuperAdmin ? ['CompanyId', 'Group'] : ['Group']
    this.dataTable.options.sortDesc = this.userIsSuperAdmin ? [false, false] : [false]
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ toTitleCase },

    openCopySettingsDialog () {
      this.copySettingsDialogVisible = true
    },

    onCopySuccess () {
      this.selectedSettings = []
      this.getData()
    },

    getFilterData () {
      this.getCompanies()
    },

    getCompanies () {
      if (!this.userCan('Companies', PermissionEnum.API)) return

      API.Resource.Company.DropDownList()
          .then(response => {
            if (API.isResponseSuccess(response)) {
              this.filterData.CompanyId.Items = collect(response.data?.data?.items || [], CompanyModel)
            }
          })
          .catch(e => { })
          .finally(() => { })
    }
  }
}
</script>

<style scoped>

</style>
