import BaseModel         from '@/lib/data/model/BaseModel'
import ElectionCategoryValidator from '@/api/validations/election/ElectionCategoryValidator'
import ElectionCategoryResource  from '@/api/resources/election/ElectionCategoryResource'
import API from '@/api/Api'

export default class ElectionCategoryModel extends BaseModel {
  constructor (payload = {}, resource = ElectionCategoryResource, validator = ElectionCategoryValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.ElectionCategory.Get(this.Id)
  }

  async Create () {
    return await API.Resource.ElectionCategory.Create(this.clone())
  }

  async Update () {
    return await API.Resource.ElectionCategory.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.ElectionCategory.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.ElectionCategory.Delete(this.Id)
  }
}
