<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="primary"
                  @click="createItem()"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-plus
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`${ $options.name }.Table.Button.New`).toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>
                <div class="flex-grow-1" />

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.Email`]="{item}">
              <v-switch
                v-model="item.Email"
                :disabled="!userCanManage || item.isElectionEnded()"
                :readonly="!userCanManage || item.isElectionEnded()"
                class="ma-0 pt-0 d-inline-flex"
                color="indigo"
                hide-details
                inset
                @change="item.Update()"
              />
            </template>

            <template #[`item.Sms`]="{item}">
              <v-switch
                v-model="item.Sms"
                :disabled="!userCanManage || item.isElectionEnded() || !userIsSuperAdmin"
                :readonly="!userCanManage || item.isElectionEnded()"
                class="ma-0 pt-0 d-inline-flex"
                color="green"
                hide-details
                inset
                @change="item.Update()"
              />
            </template>

            <template
              v-if="userCanDelete || userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <v-speed-dial
                v-if="userCanAccess && userIsSuperAdmin"
                v-model="item.fabAdmin"
                class="d-inline-block ml-1"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fabAdmin"
                    color="amber"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fabAdmin">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="amber"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="ml-1 white--text"
                      color="amber"
                      depressed
                      fab
                      target="_blank"
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="onSendResults(item)"
                    >
                      <v-icon color="grey darken-3">
                        mdi-email-seal
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="grey--text text--darken-3">
                    Αποστολή Αποτελεσμάτων
                  </span>
                </v-tooltip>

                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="amber"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="ml-1 white--text"
                      color="amber"
                      depressed
                      fab
                      target="_blank"
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="onClearCache(item)"
                    >
                      <v-icon color="grey darken-3">
                        mdi-cached
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="grey--text text--darken-3">
                    Clear Cache
                  </span>
                </v-tooltip>

                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="amber"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="ml-1 white--text"
                      color="amber"
                      depressed
                      fab
                      target="_blank"
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="onGeneratePdf(item)"
                    >
                      <v-icon color="grey darken-3">
                        mdi-file-pdf-box
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="grey--text text--darken-3">
                    Δημιουργία PDF
                  </span>
                </v-tooltip>

                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="amber"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="ml-1 white--text"
                      color="amber"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="onResultsItem(item)"
                    >
                      <v-icon color="grey darken-3">
                        mdi-clipboard-list
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="grey--text text--darken-3">
                    Αποτελέσματα
                  </span>
                </v-tooltip>

                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="amber"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="ml-1 white--text"
                      color="amber"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="onSettingsItem(item)"
                    >
                      <v-icon color="grey darken-3">
                        mdi-security
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="grey--text text--darken-3">
                    Ρυθμίσεις
                  </span>
                </v-tooltip>
              </v-speed-dial>

              <v-speed-dial
                v-model="item.fab"
                class="d-inline-block ml-1"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  v-if="userCanDelete"
                  :open-delay="650"
                  bottom
                  color="red"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanDelete"
                      :disabled="item.loadingDelete"
                      :loading="item.loadingDelete"
                      class="ml-1 white--text"
                      color="red"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="deleteItem(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Delete')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanAccess"
                  :open-delay="650"
                  bottom
                  color="info"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanAccess"
                      :disabled="item.loadingView"
                      :loading="item.loadingView"
                      class="ml-1 white--text"
                      color="info"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="viewItem(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.View')" />
                </v-tooltip>

                <template v-if="userCanManage && !item.isElectionEnded()">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="grey"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :disabled="item.loadingEdit"
                        :loading="item.loadingEdit"
                        class="ml-1 white--text"
                        color="grey"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                        @click.stop="editItem(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('Common.Button.Edit')" />
                  </v-tooltip>
                </template>

                <template v-if="userCan('ElectionMembers', PermissionEnum.ACCESS)">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="grey"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :color="$t('Route.Members.Color')"
                        :to="{name: 'ElectionMembers', params: {id: Base64AES.encrypt(item.Id), election: item}}"
                        class="ml-1 white--text"
                        depressed
                        fab
                        x-small
                        v-on="on"
                      >
                        <v-icon>{{ $t('Route.Members.Icon') }}</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('Route.Members.Title')" />
                  </v-tooltip>
                </template>

                <template v-if="userCanManage && item.Email && !item.isElectionEnded()">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="indigo"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :disabled="item.LoadingEmail"
                        :loading="item.LoadingEmail"
                        class="ml-1 white--text"
                        color="indigo"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                        @click.stop="sendNotification(item, 'email')"
                      >
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('Common.Button.Send') }} {{ $t('Common.Button.Email') }}
                    </span>
                  </v-tooltip>
                </template>

                <template v-if="userCanManage && item.Sms && !item.isElectionEnded()">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="green"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :disabled="item.LoadingSms"
                        :loading="item.LoadingSms"
                        class="ml-1 white--text"
                        color="green"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                        @click.stop="sendNotification(item, 'sms')"
                      >
                        <v-icon>mdi-cellphone-message</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('Common.Button.Send') }} {{ $t('Common.Button.Sms') }}
                    </span>
                  </v-tooltip>
                </template>

                <template v-if="userCanAdd && !item.isElectionStarted()">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="purple"
                  >
                    <template #activator="{on}">
                      <v-btn
                        class="ml-1 white--text"
                        color="purple"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                        @click.stop="showCandidateImportDialog(item)"
                      >
                        <v-icon>mdi-import</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('Common.Button.Import') }} Υποψηφίων
                    </span>
                  </v-tooltip>
                </template>

                <template v-if="userCanAccess && item.Pdf">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="red"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :href="item.Pdf"
                        class="ml-1 white--text"
                        color="red"
                        depressed
                        fab
                        target="_blank"
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Κατέβασμα PDF Αποτελεσμάτων
                    </span>
                  </v-tooltip>
                </template>

                <template v-if="userCanAccess && item.VotersPdf">
                  <v-tooltip
                    :open-delay="650"
                    bottom
                    color="purple"
                  >
                    <template #activator="{on}">
                      <v-btn
                        :href="item.VotersPdf"
                        class="ml-1 white--text"
                        color="purple"
                        depressed
                        fab
                        target="_blank"
                        x-small
                        v-on="on"
                        @click.native="item.fab = false"
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Κατέβασμα PDF Ψηφισάντων
                    </span>
                  </v-tooltip>
                </template>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <election-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :categories="categoriesList"
      :companies="filterData.CompanyId.Items"
      :item="selectedItem"
      :item-view-mode="itemViewMode"
      :members="membersList"
      :visible.sync="editDialogVisible"
      @form:save:success="getData"
    />

    <election-settings-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :item="settingsItem"
      :item-view-mode="itemViewMode"
      :visible.sync="settingsDialogVisible"
      @form:save:success="getData"
    />

    <election-results-dialog
      v-if="userCanManage && userIsSuperAdmin"
      :item="resultsItem"
      :item-view-mode="itemViewMode"
      :visible.sync="resultsDialogVisible"
      @form:save:success="getData"
    />

    <election-candidates-import-dialog
      v-if="userCanAdd"
      :election="selectedItem"
      :visible.sync="candidatesImportDialogVisible"
    />
  </v-container>
</template>

<script>
import DataTable                      from '@/mixins/table/dataTable'
import DataTableActions               from '@/mixins/table/dataTableActions'
import i18nRouteMeta                  from '@/mixins/i18n/i18nRouteMeta'
import ElectionModel                  from '@/api/models/election/ElectionModel'
import { themeUtils }                 from '@/lib/utils'
import API                            from '@/api/Api'
import { capitalize }                 from '@/lib/utils/string'
import { Base64AES }                  from '@/lib/crypto/crypto'
import PermissionEnum                 from '@/api/enums/PermissionEnum'
import ElectionSettingsDialog         from '@/theme/default/views/election/ElectionSettingsDialog.vue'
import ElectionSettingModel           from '@/api/models/election/ElectionSettingModel'
import ElectionCandidatesImportDialog from '@/theme/default/views/election/ElectionCandidatesImportDialog.vue'
import ElectionResultsDialog          from '@/theme/default/views/election/ElectionResultsDialog.vue'
import ElectionResultsModel           from '@/api/models/election/ElectionResultsModel'

const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')
const ElectionEditDialog = () => themeUtils.importThemeComponent('views/election/ElectionEditDialog')

export default {
  name      : 'Elections',
  components: {
    ElectionResultsDialog,
    ElectionCandidatesImportDialog,
    ElectionSettingsDialog,
    DataTableSearch,
    DataTableFilter,
    ElectionEditDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, DataTable, DataTableActions],
  props     : {},
  dataStore : {},
  enums     : { PermissionEnum },
  dataModel : ElectionModel,
  data () {
    return {
      resultsDialogVisible         : false,
      settingsDialogVisible        : false,
      candidatesImportDialogVisible: false,

      resultsItem   : null,
      settingsItem  : null,
      membersList   : [],
      categoriesList: [],

      dataTable: {
        customFilter: {},
        filter      : {
          Active   : null,
          CompanyId: null
        },
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        headers: [
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Id`) ? this.$t(`${ this.$options.name }.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Name`) ? this.$t(`${ this.$options.name }.Table.Headers.Name`) : 'Name',
            value   : 'Name',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Company`) ? this.$t(`${ this.$options.name }.Table.Headers.Company`) : 'Company',
            value   : 'Company',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.StartedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.StartedAt`) : 'StartedAt',
            value   : 'StartedAtFmt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.EndedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.EndedAt`) : 'EndedAt',
            value   : 'EndedAtFmt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Sms`) ? this.$t(`${ this.$options.name }.Table.Headers.Sms`) : 'Sms',
            value   : 'Sms',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Email`) ? this.$t(`${ this.$options.name }.Table.Headers.Email`) : 'Email',
            value   : 'Email',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.ActionStartedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.ActionStartedAt`) : 'ActionStartedAt',
            value   : 'ActionStartedAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.ActionEndedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.ActionEndedAt`) : 'ActionEndedAt',
            value   : 'ActionEndedAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.ActionVotesAt`) ? this.$t(`${ this.$options.name }.Table.Headers.ActionVotesAt`) : 'ActionVotesAt',
            value   : 'ActionVotesAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.CreatedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.CreatedAt`) : 'CreatedAt',
            value   : 'CreatedAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Actions`) ? this.$t(`${ this.$options.name }.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 104
          }
        ]
      }
    }
  },
  computed: {
    Base64AES () {
      return Base64AES
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onClearCache (item) {
      item.ClearCache()
        .then(response => {
          this.handleClearCacheResponse(response)
        })
        .catch(e => {
          this.handleClearCacheResponse(e.response)
        })
        .finally(() => {
        })
    },
    handleClearCacheResponse (response) {
      const data = API.responseData(response) || null

      if (API.isResponseSuccess(response) && data) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Success.Generic'),
          type   : 'success',
          icon   : 'mdi-check-circle',
          timeout: 3000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'mdi-alert',
          timeout: 3000
        })
      }
    },

    onSendResults (item) {
      item.SendResults()
        .then(response => {
          this.handleSendResultsResponse(response)
        })
        .catch(e => {
          this.handleSendResultsResponse(e.response)
        })
        .finally(() => {
        })
    },
    handleSendResultsResponse (response) {
      const data = API.responseData(response) || null

      if (API.isResponseSuccess(response) && data) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Success.Generic'),
          type   : 'success',
          icon   : 'mdi-check-circle',
          timeout: 3000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'mdi-alert',
          timeout: 3000
        })
      }
    },

    onGeneratePdf (item) {
      item.GeneratePdf()
        .then(response => {
          this.handleGeneratePdfResponse(response)
        })
        .catch(e => {
          this.handleGeneratePdfResponse(e.response)
        })
        .finally(() => {
        })
    },
    handleGeneratePdfResponse (response) {
      const data = API.responseData(response) || null

      if (API.isResponseSuccess(response) && data) {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Success.Generic'),
          type   : 'success',
          icon   : 'mdi-check-circle',
          timeout: 3000
        })
      } else {
        this.$bus.$emit('app-show-notification', {
          body   : this.$t('Common.Error.Generic'),
          type   : 'error',
          icon   : 'mdi-alert',
          timeout: 3000
        })
      }
    },

    onSettingsItem (item) {
      this.itemViewMode = false

      item.GetSettings()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.settingsItem = new ElectionSettingModel(data)
            this.settingsDialogVisible = true
          }
        })
        .catch(e => {})
        .finally(() => {})
    },

    onResultsItem (item) {
      this.itemViewMode = false

      item.GetResults()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.resultsItem = new ElectionResultsModel(data)
            this.resultsDialogVisible = true
          }
        })
        .catch(e => {})
        .finally(() => {})
    },

    showCandidateImportDialog (item) {
      this.selectedItem = item
      this.candidatesImportDialogVisible = true
    },

    sendNotification (item, type) {
      const typeName = type === 'email' ? capitalize(type) : type.toUpperCase()

      this.$root.$confirm(
        this.$t('Elections.Confirm.Notification.Title', { Type: typeName }),
        this.$t('Elections.Confirm.Notification.SubTitle', { Type: typeName }),
        this.$t('Elections.Confirm.Notification.Body', {
          Name   : item.Name,
          Type   : typeName,
          Company: item.Company
        }),
        {
          color   : type === 'email' ? 'indigo' : 'green',
          icon    : 'mdi-alert',
          validate: this.$te('Elections.Confirm.Notification.Validate') ? {
            text  : this.$t('Elections.Confirm.Notification.Validate.Text'),
            answer: this.$t('Elections.Confirm.Notification.Validate.Answer')
          } : false,
          coloredBorder: false,
          dark         : true,
          button       : {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Common.Button.Yes') }
          }
        }
      ).then(response => {
        if (response) {
          if (type === 'email') {
            this.$set(item, 'LoadingEmail', true)
          } else {
            this.$set(item, 'LoadingSms', true)
          }

          item.NotifyAll(type).finally(() => {
            if (type === 'email') {
              this.$set(item, 'LoadingEmail', false)
            } else {
              this.$set(item, 'LoadingSms', false)
            }
          })
        }
      })
    },

    getFilterData () {
      API.Resource.Election.Filters()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.filterData.CompanyId.Items = data?.Companies || []
            this.membersList = data?.Members || []
            this.categoriesList = data?.Categories || []
          }
        })
        .catch(e => {
        })
        .finally(() => {
        })
    }
  }
}
</script>

<style scoped>

</style>
