export default {

  App: {
    Title: 'Πλατφόρμα Ηλεκτρονικής Ψηφοφορίας',
    Desc : '',
    Img  : ''
  },

  Search: {
    Title: 'Αναζήτηση'
  },

  Message: {
    Loading: {
      Title   : 'Φόρτωση',
      Wait    : 'Παρακαλώ Περιμένετε',
      Progress: '%{progress}%'
    },

    Sort: {
      Title : 'Ταξινόμηση',
      SortBy: 'Ταξινόμηση με'
    },

    Group: {
      Title  : 'Ομαδοποίηση',
      GroupBy: 'Ομαδοποίηση ανά'
    },

    Filter: {
      Title   : 'Φιλτράρισμα',
      FilterBy: 'Φιλτράρισμα ανά κατηγορία'
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε σε οριστική διαγραφή;',
      Validate: {
        Text  : 'Για διαγραφή, πληκτρολογήστε <span class="font-weight-regular error--text">@:Common.Confirm.Delete.Validate.Answer</span> στο παρακάτω πλαίσιο κειμένου.',
        Answer: 'sudo delete'
      }
    }
  },

  Date: {
    Title     : 'Ημερομηνία | Ημερομηνίες',
    Today     : 'Σήμερα',
    Day       : 'Ημέρα | Ημέρες',
    Week      : 'Εβδομάδα | Εβδομάδες',
    Month     : 'Μήνας | Μήνες',
    Year      : 'Έτος | Έτη',
    Period    : 'Περίοδος | Περίοδοι',
    PeriodFrom: 'Από',
    PeriodTo  : 'Έως',
    Expire    : 'Λήξη'
  },

  Button: {
    All            : 'Όλα',
    Save           : 'Αποθήκευση',
    SaveAndGenerate: 'Δημιουργία Αποτελεσμάτων',
    Submit         : 'Καταχώρηση',
    Update         : 'Ενημέρωση',
    Copy           : 'Αντιγραφή',
    View           : 'Προβολή',
    ViewAll        : 'Προβολή Όλων',
    New            : 'Δημιουργία',
    Create         : 'Δημιουργία',
    Continue       : 'Συνέχεια',
    Edit           : 'Επεξεργασία',
    Export         : 'Εξαγωγή',
    Import         : 'Εισαγωγή',
    Delete         : 'Διαγραφή',
    Remove         : 'Διαγραφή',
    Reset          : 'Επαναφορά',
    Login          : 'Σύνδεση',
    Logout         : 'Αποσύνδεση',
    Cancel         : 'Ακύρωση',
    Close          : 'Κλείσιμο',
    Send           : 'Αποστολή',
    Yes            : 'Ναι',
    No             : 'Όχι',
    Ok             : 'Οκ',
    Next           : 'Επόμενο',
    Prev           : 'Προηγούμενο',
    Email          : 'Email',
    Sms            : 'SMS',
    Toggle         : {
      Active  : 'Ενεργό',
      Inactive: 'Ανενεργό',

      ActiveF   : 'Ενεργή',
      InactiveF : 'Ανενεργή',
      ActiveFP  : 'Ενεργές',
      InactiveFP: 'Ανενεργές',

      ActiveM   : 'Ενεργός',
      InactiveM : 'Ανενεργός',
      ActiveMP  : 'Ενεργοί',
      InactiveMP: 'Ανενεργοί'
    }
  },

  Success: {
    Generic: 'Ολοκληρώθηκε με επιτυχία',
    Save   : 'Αποθηκεύτηκε με επιτυχία',
    Update : 'Ενημερώθηκε με επιτυχία',
    Delete : 'Διαγράφηκε με επιτυχία'
  },

  Error: {
    Generic    : 'Υπήρξε κάποιο πρόβλημα. Παρακαλώ προσπαθήστε ξανά.',
    Staging    : 'Προσοχή! Αυτό είναι DEMO περιβάλλον.',
    Development: 'Προσοχή! Αυτό είναι DEV περιβάλλον.'
  },

  Misc: {
    From     : 'Από',
    To       : 'Σε',
    Locale   : 'Γλώσσα',
    CreatedAt: '@:Common.Button.Create',
    UpdatedAt: '@:Common.Button.Update',
    DeletedAt: '@:Common.Button.Delete'
  }
}
