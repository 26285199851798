<template>
  <div class="pa-1 pa-sm-6">
    <v-img
      class="ma-2 ma-sm-0"
      contain
      height="150"
      position="center"
      src="/img/logo/logo-dark.png"
    />

    <v-card-title class="justify-center">
      {{ $t('Users.Login.Title') }}
    </v-card-title>

    <v-card-text v-if="isMaintenanceMode">
      <v-alert
        border="bottom"
        class="ma-0"
        prominent
        text
        type="warning"
      >
        {{ $t('Common.Error.MaintenanceMode') }}
      </v-alert>
    </v-card-text>

    <v-card-text v-else>
      <v-form
        ref="form"
        v-model="isFormValid"
        @submit.prevent
      >
        <v-alert
          v-if="isDevelopment || isStaging"
          border="left"
          class="mb-8"
          color="warning"
          dense
          text
          type="error"
        >
          {{ isStaging ? $t('Common.Error.Staging') : $t('Common.Error.Development') }}
        </v-alert>

        <v-text-field
          v-model="model.Username"
          :disabled="isAuthLoading"
          :label="$t('Users.Login.Username')"
          :placeholder="$t('Users.Login.Username')"
          :rules="model.validator.vuetifyFormFieldRules('Username')"
          clearable
          name="username"
          outlined
          prepend-inner-icon="mdi-account-circle"
          type="text"
        />

        <div class="my-2" />

        <v-text-field
          v-model="model.Password"
          :disabled="isAuthLoading"
          :label="$t('Users.Login.Password')"
          :placeholder="$t('Users.Login.Password')"
          :rules="model.validator.vuetifyFormFieldRules('Password')"
          clearable
          name="password"
          outlined
          prepend-inner-icon="mdi-lock"
          type="password"
        />

        <v-alert
          v-model="authError"
          class="v-alert--opel ma-0"
          dense
          outlined
          type="error"
        >
          {{ authErrorMessage }}
        </v-alert>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-block justify-center px-4">
      <v-btn
        v-if="isMaintenanceMode"
        :large="$vuetify.breakpoint.smOnly"
        :x-large="$vuetify.breakpoint.mdAndUp"
        block
        class="px-4 px-sm-8 px-md-12"
        color="warning"
        dark
        depressed
        @click="ping"
        v-text="$t('Common.Button.Update')"
      />

      <template v-else>
        <v-btn
          :disabled="!isFormValid || isAuthLoading"
          :large="$vuetify.breakpoint.smOnly"
          :loading="isAuthLoading"
          :x-large="$vuetify.breakpoint.mdAndUp"
          block
          class="px-4 px-sm-8 px-md-12"
          color="brand-primary"
          depressed
          @click="authenticate"
          v-text="$t('Common.Button.Login')"
        />

        <v-btn
          :large="$vuetify.breakpoint.smAndUp"
          :small="$vuetify.breakpoint.xs"
          :to="{name: 'ForgotPassword'}"
          class="mt-4 text-capitalize"
          plain
          text
        >
          <span>Ξέχασα τον κωδικό πρόσβασης</span>
          <v-icon v-text="'mdi-chevron-right'" />
        </v-btn>
      </template>
    </v-card-actions>
  </div>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import LoginModel    from '@/api/models/user/LoginModel'

export default {
  name      : 'Login',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {
      isFormValid  : false,
      componentData: new LoginModel()
    }
  },
  computed: {
    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    window.addEventListener('keydown', this.onKeyDown)
    if (this.$refs.form) this.$refs.form.reset()
    this.user = null
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  destroyed () {},
  methods: {
    onKeyDown (e) {
      if (e.keyCode === 13 && !this.isAuthLoading) {
        e.preventDefault()
        this.authenticate()
      }
    },

    authenticate () {
      this.validateForm()

      if (this.isFormValid) {
        this.login(this.model.Username, this.model.Password)
      }
    },

    validateForm (deep = false) {
      this.isFormValid = true
      if (this.$refs?.form) this.isFormValid = this.$refs.form.validate()
      if (this.model?.validator) {
        this.isFormValid = (deep ? this.model.validate() : this.model.validator.validate())
        // eslint-disable-next-line no-console
        console.log(this.model.validator.constructor.name, this.model.errors)
      }
      return this.isFormValid
    },

    async ping () {

    }
  }
}

</script>

<style scoped>

</style>
